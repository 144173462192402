@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  margin: 0 auto;
}
.loadingSpinner {
  width: 46px;
  height: 46px;
  border: 5px solid #344054; /* Light grey */

  border-top: 5px solid rgba(52, 64, 84, 0.2); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
@media only screen and (max-width: 520px) {
  .loadingSpinner {
    width: 36px;
    height: 36px;
    border: 4px solid #344054; /* Light grey */

    border-top: 4px solid rgba(52, 64, 84, 0.2); /* Black */
  }
}
