@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
#root {
  min-height: 100vh;
}

body {
  min-height: 100vh;

  margin: 0 auto;
  overflow-x: hidden;
  word-wrap: break-word;
  background: #f9fafb;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}

.mainWrapper {
  max-width: 1116px;
  margin: 0 auto;
  width: 90%;
}
.overflow {
  scrollbar-width: thin;
  scrollbar-color: #1ced86 rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.overflow::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.overflow::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.overflow::-webkit-scrollbar-thumb {
  background: #1ced86;
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
