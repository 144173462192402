.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  background: #fff;
  box-shadow: 0px 10px 16px -4px rgba(118, 127, 146, 0.06),
    0px 4px 6px -2px rgba(118, 127, 146, 0.03);
  padding: 15px 0;
  height: 72px;
  display: flex;
  align-items: center;
}
.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  width: 93%;
  display: flex;
  align-items: center;
  gap: 28px;
}
.logoContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
.logoText {
  color: #101828;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.6px;
  white-space: nowrap;
}

.navItems {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.navItem {
  color: #344054;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 8px 12px;
}
.navActive {
  color: #344054;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 8px;
  background: #eaecf0;
}
.buttonContainer {
  margin-left: auto;
}
.icon {
  display: none;
}
.button {
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.3s;
  white-space: nowrap;
}
.button:hover {
  background: #eaecf0;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    justify-content: space-between;
  }

  .navItems {
    flex-direction: column;
    gap: 10px;
    max-width: 320px;
    background: #f9fafb;
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: 72px;
    right: 0;
    padding: 40px 0;

    width: 100%;
    height: calc(100vh - 72px);
    overflow-y: auto;
    border-left: 1px solid rgba(208, 213, 221, 0.6);
    box-shadow: 0px 10px 16px -4px rgba(118, 127, 146, 0.06),
      0px 4px 6px -2px rgba(118, 127, 146, 0.03);
  }
  .sidebar {
    transform: translateX(0);
    right: 0;
  }
  .buttonContainer {
    margin: 0;
  }

  .button {
    font-size: 14px;
    padding: 10px 15px;
  }
  .icon {
    display: block;
    cursor: pointer;
    color: #344054;
    font-size: 28px;
  }
}

@media only screen and (max-width: 520px) {
  .logoText {
    font-size: 24px;
  }
}
