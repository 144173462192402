.pagination {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  list-style: none;
  padding: 15px;
}
.pageNumberContainer {
  display: flex;
  gap: 5px;
  list-style: none;
  padding: 0;
}

.pagination .paginationItem {
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  color: #182230;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #d0d5dd;
}

@media screen and (max-width: 640px) {
  .pagination .paginationItem {
    width: 36px;
    height: 36px;
    font-size: 13px;
  }
}

.pagination .paginationItem.active {
  background: #f5f6f7;
  color: #18332e;
}

.pagination .paginationItem.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.dekstopArrow,
.mobileArrow {
  display: flex;

  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
}
.arrow {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 142.857%;
}
.arrowIcon {
  margin-top: 2px;
}

/* .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.arrow.right {
  transform: rotate(45deg);
} */
