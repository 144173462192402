.wrapper {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
@media only screen and (max-width: 520px) {
  .wrapper {
    gap: 30px;
  }
}
