.tableContainer {
  overflow-x: auto;
}

.tableContainer table {
  min-width: 100%;

  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.action {
  display: flex;
  align-items: center;
  gap: 6px;
}
.tableContainer tbody {
  min-height: 100px;
}
.tableContainer th {
  color: #475467;
  font-family: Inter;
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;
  padding: 12px 15px;
  white-space: nowrap;
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
  background: #f9fafb;
}
/* .tableContainer th:first-child {
  padding-left: 0;
}
.tableContainer th:last-child {
  padding-left: 0;
} */
.tableContainer td {
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  padding: 12px 15px;
  white-space: nowrap;
}
.shortend {
  display: flex;
  align-items: center;
  gap: 3px;
}

.copyIcon {
  cursor: pointer;
  color: #344054;
  position: relative;
  font-size: 14px;
  min-width: 14px;
}

.amount,
.blockedNumber,
.time {
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  text-align: center;
  padding: 4px 6px;
}
@media only screen and (max-width: 991px) {
  .tableContainer td {
    font-size: 12px;
  }
}
/* Firefox */
.tableContainer {
  scrollbar-width: thin;
  scrollbar-color: #7f56d9 rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.tableContainer::-webkit-scrollbar {
  width: 5px;
  max-height: 5px;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #7f56d9;
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
